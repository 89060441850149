<template>
  <section
    class="position-relative"
    :class="sectionClass"
    :style="sectionStyles"
  >
    <!-- background/hero image -->
    <div class="hda-header">
      <slot name="headerBg">
        <div class="hda-header-image" :style="bgImage"></div>
      </slot>
    </div>
    <!-- content -->
    <div
      class="container-lg pb-space-md pb-md-space-lg position-relative"
      ref="hdaContainer"
    >
      <div class="mx-space-xxs degree-intro-top position-relative">
        <div
          v-if="slots.aboveContent"
          ref="aboveContent"
          class="above-content-slot"
        >
          <slot name="aboveContent"> </slot>
        </div>
        <div class="row no-gutters" :class="containerClass">
          <div class="col-12 col-md-6 col-lg-8">
            <div class="mx-space-sm mx-lg-space-md mb-space-sm mb-md-space-md">
              <h1
                class="mb-space-md mb-md-space-sm pt-space-sm pt-md-space-md"
                :class="titleClass"
              >
                {{ title }}
              </h1>
              <slot name="text" />
              <a
                :target="linkTarget"
                :class="linkClass"
                :href="linkUrl"
                :aria-label="linkText"
                @click="handleClick"
              >
                {{ linkText }}
              </a>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div
              class="mx-space-sm ms-md-space-md ms-lg-space-sm mb-space-md mt-md-space-md"
            >
              <div class="fact-card border-light" :class="factCardClass">
                <div class="pt-space-lg px-space-sm px-md-space-md pb-space-lg">
                  <p
                    class="mb-space-sm px-space-xxs degree-label d-inline-block"
                    :class="factCardTitleClass"
                  >
                    {{ infoTitle }}
                  </p>

                  <div class="d-flex mb-space-xs">
                    <img
                      :src="CalendarAlt"
                      alt="Next start date: "
                      class="me-space-xxs fs-xs icon-class"
                    />
                    <p class="mb-0" :class="factCardItemClass">
                      Next start date:
                      <span class="fw-bold">{{ nextStartDate }}</span>
                    </p>
                  </div>

                  <hr class="text-dark-3 mb-space-xs mt-0 fact-block-break" />

                  <div
                    v-for="(item, itemIndex) in infoList"
                    class="d-flex mb-space-xxs"
                    :key="itemIndex"
                  >
                    <img
                      :src="CheckCircle"
                      :alt="(item.description as string)"
                      class="me-space-xxs fs-xs icon-class"
                    />
                    <p class="mb-0" :class="factCardItemClass">
                      {{ item.description }}
                      <span class="fw-bold">{{ item.value }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <slot name="belowContent"></slot>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import CalendarAlt from "@/assets/img/calendar-alt.svg";
import CheckCircle from "@/assets/img/check-circle.svg";
import { LinkTarget, TitleVariant, VariantOption } from "@/types/types";
import { useWindowSize } from "@vueuse/core";

interface InfoListType {
  [key: string]: string | number;
}
const props = withDefaults(
  defineProps<{
    infoList: InfoListType[];
    nextStartDate: string;
    linkTarget?: LinkTarget;
    linkUrl?: string;
    linkTitle?: string;
    linkText?: string;
    linkAsButton?: boolean;
    linkButtonVariant?: VariantOption;
    linkTextSize?: TitleVariant;
    linkTextVariant?: VariantOption;
    linkTextWeight?: string;
    containerBgColor?: VariantOption;
    sectionBgColor?: VariantOption;
    factCardBgColor?: VariantOption;
    factCardItemTextColor?: VariantOption;
    factCardTitleSize?: TitleVariant;
    factCardTitleColor?: VariantOption;
    factCardTitleBgColor?: VariantOption;
    bgImageSource: string;
    infoTitle?: string;
    title: string;
    titleSize?: TitleVariant;
    titleVariant?: VariantOption;
  }>(),
  {
    linkTarget: "_self",
    linkUrl: "https://asuonline.asu.edu/",
    linkTitle: "Lorem ipsum",
    linkText: "Lorem ipsum",
    linkAsButton: false,
    linkButtonVariant: "primary",
    linkTextSize: "medium",
    linkTextVariant: "primary",
    linkTextWeight: "normal",
    containerBgColor: "white",
    sectionBgColor: "light-2",
    factCardBgColor: "light-2",
    factCardItemTextColor: "dark-3",
    factCardTitleSize: "small",
    factCardTitleColor: "white",
    factCardTitleBgColor: "dark-3",
    infoTitle: "Quick facts",
    titleSize: "medium",
    titleVariant: "dark-3",
  }
);

const minHeroHeight = ref();
const slots = useSlots();
const aboveContent = ref();
const emit = defineEmits(["onLinkClick"]);
const { width } = useWindowSize();

const factCardClass = computed(() => {
  return `bg-${props.factCardBgColor}`;
});
const factCardItemClass = computed(() => {
  return `text-${props.factCardItemTextColor}`;
});
const bgImage = computed(() => {
  return { "background-image": `url('${props.bgImageSource}')` };
});
const containerClass = computed(() => {
  return `bg-${props.containerBgColor}`;
});
const titleClass = computed(() => {
  return `h1-${props.titleSize} text-${props.titleVariant}`;
});
const sectionClass = computed(() => {
  return `bg-${props.sectionBgColor}`;
});
const sectionStyles = computed(() => {
  // sets min height to make sure the content above the container always fits in the hero image section
  return slots.aboveContent
    ? `--header-image-min-height: ${minHeroHeight.value}px;`
    : null;
});
const factCardTitleClass = computed(() => {
  return `fs-${props.factCardTitleSize} text-${props.factCardTitleColor} bg-${props.factCardTitleBgColor}`;
});
const linkClass = computed(() => {
  let classNames = "";

  if (props.linkAsButton) {
    classNames += `px-space-xs py-space-xxs btn btn-${props.linkButtonVariant} `;
  }
  classNames += `fs-${props.linkTextSize} text-${props.linkTextVariant} fw-${props.linkTextWeight}`;

  return classNames;
});

watch(width, () => {
  // sets min height of hero image to make sure content in aboveContent slot never exceeds window height.
  slots.aboveContent && aboveContent.value.scrollHeight
    ? (minHeroHeight.value = aboveContent.value.scrollHeight + 96)
    : null;
});

const handleClick = (event: Event) => {
  emit("onLinkClick", event);
};
</script>

<style scoped lang="scss">
.fact-block-break {
  height: 0.25px;
}

.hda-header {
  height: 256px;
}

.hda-header-image {
  height: 256px;
  min-height: var(--header-image-min-height, 0);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
}

.fact-card {
  background-color: var(--light-2, #f1f1f1);
  border-radius: 3px;
  border-left: 4px solid var(--secondary, #ffc627) !important;
  min-height: 295px;
  max-width: 320px;
}

.degree-label {
  font-weight: 700;
  padding: 4px;
  color: var(--white, #ffffff);
}

.icon-class {
  width: 16px;
  height: 16px;
  margin-top: 0.25rem;
}

.degree-intro-top {
  margin-top: -48px;
}

.above-content-slot {
  position: absolute;
  bottom: 100%;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .hda-header,
  .hda-header-image {
    height: 512px;
  }
  .degree-intro-top {
    margin-top: -96px;
  }
}

@media (min-width: 1200px) {
  .hda-header,
  .hda-header-image {
    height: 512px;
  }
  .hda-header-image {
    background-attachment: scroll;
  }
}
</style>
