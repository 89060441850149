<template>
  <div>
    <!-- if fetch status is error -->
    <template v-if="fetchStatus === FetchStatus.Error">
      <div class="alert alert-danger" role="alert">
        <p class="error-message-block p-space-md">
          Oops! We are currently experiencing issues retrieving cost information
          for this program. We apologize for the inconvenience, please try back
          later.
        </p>
      </div>
    </template>
    <!-- else -->
    <template v-else>
      <!-- tabs -->
      <ul class="tab_nav d-flex mb-0 justify-content-center" role="tablist">
        <!-- Resident tab -->
        <li class="tab_nav-item">
          <button
            @click="handleActiveTab(ActiveTab.Resident)"
            class="tab_nav-link px-space-xxs pt-space-xxxs"
            data-bs-toggle="tab"
            :class="{
              'tab_nav-link--active': activeTab === ActiveTab.Resident,
            }"
            role="tab"
            aria-controls="tab_resident"
            :aria-selected="activeTab === ActiveTab.Resident"
          >
            Resident tuition
          </button>
        </li>
        <!-- Non-Resident tab -->
        <li class="nav-tab-item">
          <button
            class="tab_nav-link px-space-xxs pt-space-xxxs"
            @click="handleActiveTab(ActiveTab.NonResident)"
            data-bs-toggle="tab"
            :class="{
              'tab_nav-link--active': activeTab === ActiveTab.NonResident,
            }"
            role="tab"
            aria-controls="tab_non_resident"
            :aria-selected="activeTab === ActiveTab.NonResident"
          >
            Non-Resident tuition
          </button>
        </li>
      </ul>
      <!-- ignore -->
      <div class="tab-content bg-white">
        <template v-if="costOverride">
          <div class="py-space-md text-center">
            <h3 class="h3-small fw-bold mb-space-xs">
              {{ costCollectionCaption }}
            </h3>
            <p class="mb-0 fw-bold h2-large">{{ manualCostAmount }}</p>
          </div>
        </template>

        <template v-else>
          <div class="p-space-xs p-md-space-md text-center">
            <spinner-group v-if="fetchStatus === FetchStatus.Loading" />
            <template v-else>
              <!-- Resident tab content -->
              <div
                v-if="activeTab === ActiveTab.Resident"
                id="resident_tab"
                class="tab-pane"
                :class="{ 'show active': activeTab === ActiveTab.Resident }"
                role="tabpanel"
                aria-labelledby="resident_tab"
              >
                <div
                  class="d-flex flex-column align-items-center justify-content-center mb-space-sm"
                >
                  <h3
                    class="h3-medium bg-secondary fw-bold py-space-xxxs px-space-xs mb-space-xs"
                  >
                    {{ calculatorTitle }}
                  </h3>
                  <div v-if="isGraduate">
                    <p class="fs-small mb-space-xxxs">Graduate key:</p>
                    <p class="fs-small mb-space-xxxs">
                      9+ credits for full-time
                    </p>
                    <p class="fs-small mb-0">
                      6 credits for part-time or working students
                    </p>
                  </div>
                  <div v-else>
                    <p class="fs-small mb-space-xxxs">Undergraduate key:</p>
                    <p class="fs-small mb-space-xxxs">
                      12+ credits for full-time
                    </p>
                    <p class="fs-small mb-0">
                      6 credits for part-time or working students
                    </p>
                  </div>
                </div>
                <!-- slider -->
                <div
                  class="d-flex align-items-center mb-space-sm border border-2 border-light-3 rounded-pill px-space-xs py-space-xxs slider-border-width"
                >
                  <h3 class="h3-medium fw-bold me-space-xs">
                    {{ residentSlider }}
                  </h3>
                  <rds-slider
                    v-model="residentSlider"
                    min="1"
                    :max="residencyStatusResponse?.max_credits"
                    aria-label="Select number of credit hours per semester"
                    class="flex-fill me-space-xxxs"
                    data-cy="tuition-calculator-credits-slider"
                  ></rds-slider>
                </div>
                <p class="fs-xs mb-space-sm">
                  Drag the slider to see the tuition breakdown by credit hour
                </p>
                <!-- breakup -->
                <p class="fs-medium mb-space-xs text-start">
                  Tuition breakdown**
                </p>
                <div
                  class="accordion"
                  :id="`program_cost_${ActiveTab.Resident?.replaceAll(
                    ' ',
                    '_'
                  )}`"
                >
                  <collapse-cost-item
                    v-for="(data, index) in creditHourCost"
                    :key="index"
                    :id="generateCollapseId('res', data.id)"
                    @collapse-shown="
                      handleCollapseShown(data.title, ActiveTab.Resident)
                    "
                    @collapse-hidden="
                      handleCollapseHidden(data.title, ActiveTab.Resident)
                    "
                    :title="data.title"
                    :amount="data.amount"
                    :items="data.items"
                    collapse-title-size="small"
                    class="mb-space-xxs text-start border-light-4"
                  ></collapse-cost-item>
                </div>
                <!-- more info -->
                <div class="bg-dark-3 text-light-1 px-space-sm py-space-xs">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <p class="fs-large fw-bold mb-0">Academic Year Total</p>
                    <p
                      class="fs-large fw-bold mb-0"
                      data-cy="tuition-calculator-total-cost"
                    >
                      {{ residentAcademicYearTotal }}
                    </p>
                  </div>
                  <hr class="hr-bg-color my-space-xs mx-0" />
                  <p class="fs-xs">
                    *This is not a bill. This is only an estimate. Special class
                    fees are dependent on specific class enrollment and are not
                    included here.
                  </p>
                  <p class="fs-xs mb-0">
                    For additional cost estimates, view our
                    <nuxt-link
                      href="https://tuition.asu.edu/cost"
                      @click.native="
                        handleCostLink(
                          'standard cost of attendance',
                          'academic year total'
                        )
                      "
                      class="text-secondary rds-link"
                      >standard cost of attendance</nuxt-link
                    >.
                  </p>
                </div>
              </div>
              <!-- Non-Resident tab content -->
              <div
                class="tab-pane"
                id="non_resident_tab"
                v-else
                :class="{ 'show active': activeTab === ActiveTab.NonResident }"
                role="tabpanel"
                aria-labelledby="non_resident_tab"
              >
                <div
                  class="d-flex flex-column align-items-center justify-content-center mb-space-sm"
                >
                  <h3
                    class="h3-medium bg-secondary fw-bold py-space-xxxs px-space-xs mb-space-xs"
                  >
                    {{ calculatorTitle }}
                  </h3>
                  <div v-if="isGraduate">
                    <p class="fs-small mb-space-xxxs">Graduate key:</p>
                    <p class="fs-small mb-space-xxxs">
                      9+ credits for full-time
                    </p>
                    <p class="fs-small mb-0">
                      6 credits for part-time or working students
                    </p>
                  </div>
                  <div v-else>
                    <p class="fs-small mb-space-xxxs">Undergraduate key:</p>
                    <p class="fs-small mb-space-xxxs">
                      12+ credits for full-time
                    </p>
                    <p class="fs-small mb-0">
                      6 credits for part-time or working students
                    </p>
                  </div>
                </div>
                <!-- slider -->
                <div
                  class="d-flex align-items-center mb-space-sm border border-2 border-light-3 rounded-pill px-space-xs py-space-xxs slider-border-width"
                >
                  <h3 class="h3-medium fw-bold me-space-xs">
                    {{ nonResidentSlider }}
                  </h3>
                  <rds-slider
                    v-model="nonResidentSlider"
                    min="1"
                    :max="nonResidencyStatusResponse?.max_credits"
                    aria-label="Select number of credit hours per semester"
                    class="flex-fill me-space-xxxs"
                    data-cy="tuition-calculator-credits-slider"
                  ></rds-slider>
                </div>
                <p class="fs-xs mb-space-sm">
                  Drag the slider to see the tuition breakdown by credit hour
                </p>
                <!-- breakup -->
                <p class="fs-medium mb-space-xs text-start">
                  Tuition breakdown**
                </p>
                <div
                  class="accordion"
                  :id="`program_cost_${ActiveTab.NonResident?.replaceAll(
                    ' ',
                    '_'
                  )}`"
                >
                  <collapse-cost-item
                    v-for="(data, index) in creditHourCostNonResident"
                    :key="index"
                    :id="generateCollapseId('res', data.id)"
                    :title="data.title"
                    @collapse-shown="
                      handleCollapseShown(data.title, ActiveTab.NonResident)
                    "
                    @collapse-hidden="
                      handleCollapseHidden(data.title, ActiveTab.NonResident)
                    "
                    :amount="data.amount"
                    :items="data.items"
                    collapse-title-size="small"
                    class="mb-space-xxs text-start border-light-4"
                  ></collapse-cost-item>
                </div>
                <!-- more info -->
                <div class="bg-dark-3 text-light-1 px-space-sm py-space-xs">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <p class="fs-large fw-bold mb-0">Academic Year Total</p>
                    <p
                      class="fs-large fw-bold mb-0"
                      data-cy="tuition-calculator-total-cost"
                    >
                      {{ nonresidentAcademicYearTotal }}
                    </p>
                  </div>
                  <hr class="hr-bg-color my-space-xs mx-0" />
                  <p class="fs-xs">
                    *This is not a bill. This is only an estimate. Special class
                    fees are dependent on specific class enrollment and are not
                    included here.
                  </p>
                  <p class="fs-xs mb-0">
                    For additional cost estimates, view our
                    <nuxt-link
                      href="https://tuition.asu.edu/cost"
                      @click.native="
                        handleCostLink(
                          'standard cost of attendance',
                          'academic year total'
                        )
                      "
                      class="text-secondary rds-link"
                      >standard cost of attendance</nuxt-link
                    >.
                  </p>
                </div>
              </div>
            </template>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import {
  AsuTuitionApiResponse,
  AsuTuitionApiResponseData,
  AsuTuitionApiResponseDataFeeBreakdown,
  TermCreditHourCostData,
} from "@/types/types";
import { analyticsComposable } from "@rds-vue-ui/analytics-gs-composable";
import RdsSlider from "~/components/RdsSlider.vue";
import SpinnerGroup from "~/components/SpinnerGroup.vue";
import CollapseCostItem from "~/components/degree-page/CollapseCostItem.vue";
import CostService from "~/src/services/CostService";

enum FetchStatus {
  Loading = "loading",
  Error = "error",
  Success = "success",
  Ignore = "ignore",
}
enum ActiveTab {
  Resident = "resident tuition",
  NonResident = "non-resident tuition",
}
type Props = {
  programCategory: string;
  costOverride: boolean;
  costAcademicYear: string;
  costResidentUrl: string | null;
  costNonResidentUrl: string | null;
  costCollection: {
    amount: number;
    summary: string | null;
  } | null;
  degreeType: "Graduate" | "Undergraduate" | "Certificate";
  isMountedModal: boolean;
};
const calculatorTitle = "Your tuition estimate";
/*
 ********************************************
 * Props
 ********************************************
 */
const props = defineProps<Props>();

/*
 ********************************************
 * Data
 ********************************************
 */
const fetchStatus = ref<FetchStatus>(FetchStatus.Ignore);
const activeTab = ref<ActiveTab>(ActiveTab.Resident);
const residencyStatusResponse = ref<AsuTuitionApiResponseData | null>();
const nonResidencyStatusResponse = ref<AsuTuitionApiResponseData | null>();
const residentSlider = ref("6");
const creditHourCost = ref<TermCreditHourCostData[]>([]);
const creditHourCostNonResident = ref<TermCreditHourCostData[]>([]);
const nonResidentSlider = ref("12");
const nonresidentAcademicYearTotal = ref<string>("");
const residentAcademicYearTotal = ref<string>("");
/*
 ********************************************
 * Computed
 ********************************************
 */
const manualCostAmount = computed(() => {
  return `$${props.costCollection?.amount!.toLocaleString()}`;
});

const costCollectionCaption = computed(() => {
  let caption = "Cost caption";
  if (props.programCategory === "Graduate") {
    caption = "The cost of this program is:";
  } else if (props.programCategory === "Certificates") {
    caption = "The cost per credit hour for this program is:";
  }
  return caption;
});

const isGraduate = computed(() => {
  return props.degreeType === "Graduate";
});

/*
 ********************************************
 * Methods
 ********************************************
 */
const handleActiveTab = (tab: ActiveTab) => {
  activeTab.value = tab;
  analyticsComposable.trackLinkEvent(
    "internal link",
    "main content",
    calculatorTitle.toLowerCase(),
    tab.toLowerCase(),
    "asu online tuition calculator"
  );
};
const fetchAll = async () => {
  if (props.costResidentUrl && props.costNonResidentUrl) {
    const requests = [
      await useFetch<AsuTuitionApiResponse>(props.costResidentUrl, {
        server: false,
      }),

      await useFetch<AsuTuitionApiResponse>(props.costNonResidentUrl, {
        server: false,
      }),
    ];

    const responses = await Promise.all(requests);

    for (const [i, response] of responses.entries()) {
      if (!response.data.value) {
        fetchStatus.value = FetchStatus.Error;
        return;
      }
      if (i === 0) {
        residencyStatusResponse.value = response?.data.value?.data;

        const responseData = response?.data.value?.data;
        if (responseData) {
          calCreditHourCost(responseData);
        }
      } else {
        nonResidencyStatusResponse.value = response?.data.value?.data;
        const responseData = response?.data.value?.data;
        if (responseData) {
          calCreditHourCostNonResident(responseData);
        }
      }
    }
    fetchStatus.value = FetchStatus.Success;
  }
};

const generateCollapseId = (prefix: string, id: string) => {
  return `${prefix}_${id}`;
};
const calCreditHourCost = (
  residencyStatusResponse: AsuTuitionApiResponseData
) => {
  creditHourCost.value = Object.values(
    CostService.generateResidencyCostData(
      props.costAcademicYear,
      residencyStatusResponse,
      residentSlider.value! as keyof Omit<
        AsuTuitionApiResponseDataFeeBreakdown,
        "descr"
      >
    )
  ) as TermCreditHourCostData[];
  residentAcademicYearTotal.value = `$${CostService.calculateAcademicYearTotal(
    residencyStatusResponse,
    residentSlider.value! as keyof Omit<
      AsuTuitionApiResponseDataFeeBreakdown,
      "descr"
    >
  ).toLocaleString()}`;
};
const calCreditHourCostNonResident = (
  nonResidencyStatusResponse: AsuTuitionApiResponseData
) => {
  creditHourCostNonResident.value = Object.values(
    CostService.generateResidencyCostData(
      props.costAcademicYear,
      nonResidencyStatusResponse,
      nonResidentSlider.value! as keyof Omit<
        AsuTuitionApiResponseDataFeeBreakdown,
        "descr"
      >
    )
  ) as TermCreditHourCostData[];
  nonresidentAcademicYearTotal.value = `$${CostService.calculateAcademicYearTotal(
    nonResidencyStatusResponse,
    nonResidentSlider.value! as keyof Omit<
      AsuTuitionApiResponseDataFeeBreakdown,
      "descr"
    >
  ).toLocaleString()}`;
};
const handleCollapseShown = (text: string, component: string) => {
  analyticsComposable.trackCollapseEvent(
    "onclick",
    "open",
    "click",
    "modal",
    calculatorTitle.toLowerCase(),
    text.toLowerCase(),
    component.toLowerCase()
  );
};
const handleCollapseHidden = (text: string, component: string) => {
  analyticsComposable.trackCollapseEvent(
    "onclick",
    "close",
    "click",
    "modal",
    calculatorTitle.toLowerCase(),
    text.toLowerCase(),
    component.toLowerCase()
  );
};
const handleCostLink = (text: string, component: string) => {
  analyticsComposable.trackLinkEvent(
    "external link",
    "main content",
    text.toLowerCase(),
    component.toLowerCase(),
    calculatorTitle.toLowerCase()
  );
};
/*
 ********************************************
 * Lifecycle
 ********************************************
 */

onMounted(() => {
  if (props.costOverride) {
    // added main fetch status
    fetchStatus.value = FetchStatus.Ignore;
  } else {
    fetchStatus.value = FetchStatus.Loading;
    /* Updating to use nextTick due to known issue with nuxt
      see this thread - https://github.com/nuxt/nuxt/issues/13471#issuecomment-1449898517 */
    nextTick(() => {
      fetchAll();
    });
  }
});

watch(residentSlider, () => {
  if (residencyStatusResponse.value) {
    calCreditHourCost(residencyStatusResponse.value);
  }
});
watch(nonResidentSlider, () => {
  if (nonResidencyStatusResponse.value) {
    calCreditHourCostNonResident(nonResidencyStatusResponse.value);
  }
});
</script>

<style scoped lang="scss">
.tab_nav {
  border-bottom: 1px solid var(--rds--light-4);
  list-style: none;
  &-link {
    margin: 0 10px;
    margin-bottom: -1px;
    background-color: transparent;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    color: var(--rds--dark-3);
    padding: 0 0 4px;
    margin-right: 24px;
    font-size: 18px;
    &--active {
      color: var(--rds-light-text-emphasis);
      background-color: var(--rds-white);
      border-color: var(--rds-gray-300) var(--rds-gray-300) var(--rds-white);
      margin-right: 24px;
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
